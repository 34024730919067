import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["row", "switch"]

  connect() {
    this._updateStates();
  }

  filter(event) {
    if (event.target.checked) {
      this._activateState(event.target.dataset.filterClass)
    } else {
      this._deactivateState(event.target.dataset.filterClass)
    }
  }

  _updateStates() {
    this.switchTargets.forEach(element => {
      if (element.checked) {
        this._activateState(element.dataset.filterClass)
      } else {
        this._deactivateState(element.dataset.filterClass)
      }
    });
  }

  _activateState(state) {
    this.rowTargets.forEach(element => {
      if (element.dataset.filterClasses.split(' ').indexOf(state) < 0) return

      element.classList.remove('d-none')
    });
  }

  _deactivateState(state) {
    this.rowTargets.forEach(element => {
      if (element.dataset.filterClasses.split(' ').indexOf(state) < 0) return

      element.classList.add('d-none')
    });
  }
}
