import { Controller } from "@hotwired/stimulus"
import Cropper from 'cropperjs';

export default class extends Controller {
  static targets = ["image", "fileInput", "pictureX", "pictureY", "pictureW", "pictureH", "wrapper"]

  #cropper;

  connect() {
    this._initializeCropper();
  }

  changed() {
    if (!this.fileInputTarget.files || !this.fileInputTarget.files[0]) return;

    const fr = new FileReader();
    fr.onload = () => { this._setImage(fr.result) }
    fr.readAsDataURL(this.fileInputTarget.files[0]);
  }

  _initializeCropper() {
    if (this.#cropper) return;
    if (!this.hasImageTarget) return;

    this.#cropper = new Cropper(this.imageTarget, {
      viewMode: 2,
      dragMode: 'move',
      aspectRatio: 1,
      autoCropArea: 1,
      rotatable: false,
      scalable: false,
      data: this._initialCrop(),
      crop: () => { this._crop() }
    });
  }

  _setImage(src) {
    if (this.#cropper) {
      this.#cropper.replace(src)
    } else {
      const image = document.createElement('img')
      image.src = src;
      image.dataset.imageCropTarget = 'image'
      this.wrapperTarget.insertBefore(image, null)
      this._initializeCropper()
    }
  }

  _crop() {
    const data = this.#cropper.getData(false)
    this.pictureXTarget.value = data.x;
    this.pictureYTarget.value = data.y;
    this.pictureWTarget.value = data.width;
    this.pictureHTarget.value = data.height;
  }

  _initialCrop() {
    let data = {}
    if (this.hasPictureXTarget && this.pictureXTarget.value) {
      data.x = parseFloat(this.pictureXTarget.value)
    }
    if (this.hasPictureYTarget && this.pictureYTarget.value) {
      data.y = parseFloat(this.pictureYTarget.value)
    }
    if (this.hasPictureWTarget && this.pictureWTarget.value) {
      data.width = parseFloat(this.pictureWTarget.value)
    }
    if (this.hasPictureHTarget && this.pictureHTarget.value) {
      data.height = parseFloat(this.pictureHTarget.value)
    }
    return data;
  }
}
