import { Controller } from "@hotwired/stimulus"
import { DateTime } from "luxon";

export const YYYYMMDDHHMMSS = /^\s*([0-9]{4})[:\-_/]?([0-9]{1,2})[:\-_/]?([0-9]{1,2})(?:\s*([0-9]{1,2})[h: ]?([0-9]{1,2})(?:[:m ]?([0-9]{1,2})s?)?)?\s*$/
export const DDMMYYYYHHMMSS = /^\s*([0-9]{1,2})[:\-_/]?([0-9]{1,2})(?:[:\-_/]?([0-9]{4}))?(?:\s*([0-9]{1,2})[h: ]?([0-9]{1,2})(?:[:m ]?([0-9]{1,2})s?)?)?\s*$/
export const DDMMMYYYYHHMMSS = /^\s*([0-9]{1,2})[:\-_/ ]?(janu?a?r?i?|febr?u?a?r?i?|maar?t?|apri?l?|mei|juni?|juli?|augu?s?t?u?s?|sept?e?m?b?e?r?|okto?b?e?r?|nove?m?b?e?r?|dece?m?b?e?r?)(?:[:\-_/ ]?([0-9]{4}))?(?:\s*([0-9]{1,2})[h: ]?([0-9]{1,2})(?:[:m ]?([0-9]{1,2})s?)?)?\s*$/
export const DDDHHMMSS = /^(ma(?:a(?:n(?:d(?:ag?)?)?)?)?|di(?:n(?:s(?:d(?:ag?)?)?)?)?|wo(?:e(?:n(?:s(?:d(?:ag?)?)?)?)?)?|do(?:n(?:d(?:e(?:r(?:d(?:ag?)?)?)?)?)?)?|vr(?:i(?:j(?:d(?:ag?)?)?)?)?|za(?:t(?:e(?:r(?:d(?:ag?)?)?)?)?)?|zo(?:n(?:d(?:ag?)?)?)?|va(?:n(?:d(?:a(?:ag?)?)?)?)?|mo(?:r(?:g(?:en?)?)?)?|ov(?:e(?:r(?:m(?:o(?:r(?:g(?:en?)?)?)?)?)?)?)?)(?:\s+([0-9]{1,2})[h: ]?([0-9]{1,2})(?:[:m ]?([0-9]{1,2})s?)?)?\s*$/

export const WEEKDAYS = { ma: 1, di: 2, wo: 3, do: 4, vr: 5, za: 6, zo: 7 }
export const MONTHS = { jan: 1, feb: 2, maa: 3, apr: 4, mei: 5, jun: 6, jul: 7, aug: 8, sep: 9, okt: 10, nov: 11, dec: 12 }

export default class extends Controller {
  static targets = ["input", "hint"]

  #nowInterval;

  handleInput() {
    const date = this._parseDateTime(this.inputTarget.value, this.inputTarget.dataset.type == 'date')
    if (date == null) {
      this.inputTarget.classList.remove('is-valid')
      this.inputTarget.classList.add('is-invalid')
      this._removeHintText()
    } else {
      this.inputTarget.classList.remove('is-invalid')
      this.inputTarget.classList.add('is-valid')
      this._setHintText(date, this.inputTarget.dataset.type == 'date')
    }
  }

  _removeHintText() {
    if (this.hasHintTarget) {
      this.hintTarget.remove()
    }
  }

  _setHintText(date, dateOnly=false) {
    if (!this.hasHintTarget) {
      const hint = document.createElement('div')
      hint.setAttribute('id', this.inputTarget.id + "_hint")
      hint.classList.add('form-text')
      hint.dataset.dateTimeInputTarget = 'hint'
      this.element.insertBefore(hint, null)
    }
    while (this.hintTarget.firstChild) {
      this.hintTarget.removeChild(this.hintTarget.lastChild);
    }
    clearInterval(this.#nowInterval)
    if (date == 'now') {
      date = DateTime.now({ zone: 'Europe/Amsterdam' })
      this.#nowInterval = setInterval(() => { this._updateHintText() }, 1000)
    }
    const dateObject = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'short'
    }
    if (!dateOnly) {
      dateObject.hour = 'numeric'
      dateObject.minute = '2-digit'
      dateObject.second = '2-digit'
    }
    const text = document.createTextNode(date.toLocaleString(dateObject))
    this.hintTarget.appendChild(text)
  }

  _updateHintText() {
    if (!this.hasHintTarget) {
      return
    }
    while (this.hintTarget.firstChild) {
      this.hintTarget.removeChild(this.hintTarget.lastChild);
    }
    const date = DateTime.now({ zone: 'Europe/Amsterdam' })
    const text = document.createTextNode(date.toLocaleString({
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      second: '2-digit',
      weekday: 'short'
    }))
    this.hintTarget.appendChild(text)
  }

  _parseDateTime(value, dateOnly=false) {
    if (YYYYMMDDHHMMSS.test(value)) {
      const match = YYYYMMDDHHMMSS.exec(value)
      const year = this._parseYear(match[1])
      const month = this._parseMonth(match[2])
      const day = this._parseDay(year, month, match[3])
      const [ hour, minute, second ] = this._parseTime(match[4], match[5], match[6])
      if (dateOnly && (match[4] || match[5] || match[6])) {
        return null
      }
      return this._constructDateTime(year, month, day, hour, minute, second)
    } else if (DDMMYYYYHHMMSS.test(value)) {
      const match = DDMMYYYYHHMMSS.exec(value)
      const year = this._parseYear(match[3])
      const month = this._parseMonth(match[2])
      const day = this._parseDay(year, month, match[1])
      const [ hour, minute, second ] = this._parseTime(match[4], match[5], match[6])
      if (dateOnly && (match[4] || match[5] || match[6])) {
        return null
      }
      return this._constructDateTime(year, month, day, hour, minute, second)
    } else if (DDMMMYYYYHHMMSS.test(value)) {
      const match = DDMMMYYYYHHMMSS.exec(value)
      const year = this._parseYear(match[3])
      const month = this._parseWrittenMonth(match[2])
      const day = this._parseDay(year, month, match[1])
      const [ hour, minute, second ] = this._parseTime(match[4], match[5], match[6])
      if (dateOnly && (match[4] || match[5] || match[6])) {
        return null
      }
      return this._constructDateTime(year, month, day, hour, minute, second)
    } else if (DDDHHMMSS.test(value)) {
      const match = DDDHHMMSS.exec(value)
      const [ year, month, day ] = this._parseWeekday(match[1])
      const [ hour, minute, second ] = this._parseTime(match[2], match[3], match[4])
      if (dateOnly && (match[2] || match[3] || match[4])) {
        return null
      }
      return this._constructDateTime(year, month, day, hour, minute, second)
    } else if (value.trim() == 'nu') {
      return 'now'
    }
    return null;
  }

  _parseYear(year) {
    const parsed = parseInt(year)

    if (isNaN(parsed)) return null

    return parsed
  }

  _parseMonth(month) {
    const parsed = parseInt(month)

    if (isNaN(parsed)) return null
    if (parsed < 1 || parsed > 12) return null

    return parsed
  }

  _parseWrittenMonth(month) {
    if (!MONTHS[month.substr(0, 3)]) return null

    return MONTHS[month.substr(0, 3)]
  }

  _parseDay(parsed_year, parsed_month, day) {
    const parsed = parseInt(day)

    if (isNaN(parsed)) return null

    const current_date = DateTime.now({ zone: 'Europe/Amsterdam' })
    if (!parsed_year) {
      parsed_year = current_date.year;
    }
    const date = DateTime.local(parsed_year, parsed_month, parsed, { zone: 'Europe/Amsterdam' })

    if (!date.isValid) return null

    return parsed
  }

  _parseWeekday(day) {
    let date = DateTime.now({ zone: 'Europe/Amsterdam' })
    let days = 0
    switch(day.substr(0, 2)) {
      case "va":
        return [date.year, date.month, date.day]
      case "mo":
        days = 1
        break
      case "ov":
        days = 2
        break
      default:
        if (!WEEKDAYS[day.substr(0, 2)]) return null

        days = WEEKDAYS[day.substr(0, 2)] - date.weekday
        break
    }
    date = date.plus({ days: days > 0 ? days : days + 7 })
    return [date.year, date.month, date.day]
  }

  _parseTime(hour, minute, second) {
    return [this._parseHour(hour), this._parseMinute(minute), this._parseSecond(second)]
  }

  _parseHour(hour) {
    const parsed = parseInt(hour)

    if (isNaN(parsed)) return 0
    if (parsed < 0 || parsed > 23) return 0

    return parsed
  }

  _parseMinute(minute) {
    const parsed = parseInt(minute)

    if (isNaN(parsed)) return 0
    if (parsed < 0 || parsed > 59) return 0

    return parsed
  }

  _parseSecond(second) {
    const parsed = parseInt(second)

    if (isNaN(parsed)) return 0
    if (parsed < 0 || parsed > 59) return 0

    return parsed
  }

  _constructDateTime(year, month, day, hour, minute, second) {
    const current_date = DateTime.now({ zone: 'Europe/Amsterdam' })
    let date_year = year;
    if (year == null) {
      date_year = current_date.year;
    }
    let date = DateTime.local(date_year, month, day, hour, minute, second, { zone: 'Europe/Amsterdam', locale: 'nl' })
    if (date < DateTime.local({ zone: 'Europe/Amsterdam' }) && year == null) {
      date = date.plus({ year: 1 })
    }
    if (!date.isValid) return null
    return date
  }
}
