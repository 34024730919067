import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["autocomplete", "datalist", "input"]

  connect() {
    this._buildDatalist()
    this._buildInput()
    this._hideSelect()
    this._initialOption()
  }

  handleInput() {
    this.autocompleteTarget.classList.remove('is-invalid')
    this.autocompleteTarget.classList.remove('is-valid')
    this._updateValue();
  }

  handleChange() {
    this._updateValue();

    if (this.inputTarget.value) return;

    this.autocompleteTarget.classList.remove('is-valid')
    this.autocompleteTarget.classList.add('is-invalid')
  }

  _buildDatalist() {
    const datalist = document.createElement('datalist')
    datalist.setAttribute('id', this.inputTarget.id + "_datalist")
    datalist.dataset.selectAutocompleteTarget = 'datalist'
    this.inputTarget.querySelectorAll('option').forEach(element => {
      if (!element.value) return
      
      const option = document.createElement('option')
      option.value = element.text
      option.dataset.id = element.value
      datalist.insertBefore(option, null)
    })
    this.element.insertBefore(datalist, this.element.firstChild)
  }

  _buildInput() {
    const input = document.createElement('input')
    input.setAttribute('id', this.inputTarget.id + "_autocomplete")
    input.setAttribute('autocomplete', 'off')
    input.setAttribute('list', this.inputTarget.id + "_datalist")
    input.classList.add('form-control')
    if (this.inputTarget.classList.contains('is-invalid')) {
      input.classList.add('is-invalid')
    }
    input.dataset.action = 'change->select-autocomplete#handleChange input->select-autocomplete#handleInput'
    input.dataset.selectAutocompleteTarget = 'autocomplete'
    this.element.insertBefore(input, this.element.firstChild)
  }

  _hideSelect() {
    this.inputTarget.classList.add('d-none')
    this.inputTarget.classList.remove('is-invalid')
    this.inputTarget.classList.remove('is-valid')
  }

  _initialOption() {
    this.datalistTarget.querySelectorAll('option').forEach(element => {
      if (element.dataset.id == this.inputTarget.value) {
        this.autocompleteTarget.value = element.value
        if (!this.autocompleteTarget.classList.contains('is-invalid')) {
          this.autocompleteTarget.classList.add('is-valid')
        }
      }
    });
  }

  _updateValue() {
    this.inputTarget.value = null
    this.datalistTarget.querySelectorAll('option').forEach(element => {
      if (element.value == this.autocompleteTarget.value) {
        this.inputTarget.value = element.dataset.id
        this.autocompleteTarget.classList.remove('is-invalid')
        this.autocompleteTarget.classList.add('is-valid')
      }
    });
  }
}
