import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox", "field"]

  connect() {
    this._updateFields(this.checkboxTarget.checked);
  }

  handleChange() {
    this._updateFields(this.checkboxTarget.checked);
  }

  _updateFields(disabled) {
    this.fieldTargets.forEach(element => {
      element.disabled = disabled
    });
  }
}
