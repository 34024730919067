import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["map", "latitude", "longitude", "placeId"]

  connect() {
    this._updateMap();
  }

  changed() {
    this._updateMap();
  }

  _updateMap() {
    this.mapTarget.src = this._getMapsUrl();
  }

  _getMapsUrl() {
    if (this.hasPlaceIdTarget && this.placeIdTarget.value) {
      return "https://www.google.com/maps/embed/v1/place?q=place_id:" + this.placeIdTarget.value + "&key=" + this._mapsApiKey();
    } else if (this.hasLatitudeTarget && this.hasLongitudeTarget && this.latitudeTarget.value && this.longitudeTarget.value) {
      return "https://www.google.com/maps/embed/v1/view?zoom=17&center=" + this.latitudeTarget.value + "," + this.longitudeTarget.value + "&key=" + this._mapsApiKey();
    }
    return "https://www.google.com/maps/embed/v1/place?q=place_id:ChIJu-SH28MJxkcRnwq9_851obM&key=" + this._mapsApiKey();
  }

  _mapsApiKey() {
    return this.mapTarget.dataset.apiKey
  }
}
