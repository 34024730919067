import { Controller } from "@hotwired/stimulus"
import { createjs } from 'createjs/builds/1.0.0/createjs'

export default class extends Controller {
  connect() {
    var AdobeAn = {};

    (function (cjs, an) {

      var p; // shortcut to reference prototypes
      var lib = {}; var ss = {}; var img = {};
      lib.ssMetadata = [];


      (lib.AnMovieClip = function () {
        this.actionFrames = [];
        this.ignorePause = false;
        this.gotoAndPlay = function (positionOrLabel) {
          cjs.MovieClip.prototype.gotoAndPlay.call(this, positionOrLabel);
        }
        this.play = function () {
          cjs.MovieClip.prototype.play.call(this);
        }
        this.gotoAndStop = function (positionOrLabel) {
          cjs.MovieClip.prototype.gotoAndStop.call(this, positionOrLabel);
        }
        this.stop = function () {
          cjs.MovieClip.prototype.stop.call(this);
        }
      }).prototype = p = new cjs.MovieClip();
      // symbols:
      // helper functions:

      function mc_symbol_clone() {
        var clone = this._cloneProps(new this.constructor(this.mode, this.startPosition, this.loop, this.reversed));
        clone.gotoAndStop(this.currentFrame);
        clone.paused = this.paused;
        clone.framerate = this.framerate;
        return clone;
      }

      function getMCSymbolPrototype(symbol, nominalBounds, frameBounds) {
        var prototype = cjs.extend(symbol, cjs.MovieClip);
        prototype.clone = mc_symbol_clone;
        prototype.nominalBounds = nominalBounds;
        prototype.frameBounds = frameBounds;
        return prototype;
      }


      (lib.Wave_Smallai = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Wave_Small_ai
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#00CCFF").s().p("Ekr/AL1IAA07IAFAAIAAgDQFciXHkgRQHEgRGMBmQCXApBZAqIAAADIAGAAQBWAoCUAoQGLBmHCgQQHhgSFdiUIAFAAIAAgDQFciXHkgRQHEgRGMBmQCXApBZAqIAAADIAFAAQBXAoCUAoQGLBmHCgQQHhgSFdiUIAFAAIAAgDQFciXHkgRQHEgRGMBmQCXApBZAqIAAADIAFAAQBXAoCUAoQGKBmHDgQQHhgSFciUIAGAAIAAgDQFciXHkgRQHEgRGLBmQCYApBZAqIAAADIAFAAQBXAoCUAoQGKBmHDgQQHhgSFciUIAGAAIAAgDQFciXHkgRQHEgRGLBmQCYApBZAqIAAADIAFAAQBXAoCUAoQGKBmHDgQQHhgSFciUIAFAAIAAgDQFciXHjgRQHFgRGMBmQCYAqBYApIAAADIAFAAQBXAoCUAoQGKBmHDgQQHhgSFciUIAGAAIAAgDQFciXHjgRQHFgRGMBmQCYAqBYApIAAADIAFAAQBXAoCUAoQGKBmHDgQQHhgSFciUIAGAAIAAgDQFciXHjgRQHFgRGMBmQCYAqBYApIAAADIAFAAQBXAoCUAoQGKBmHDgQQHhgSFciUIAGAAIAAgDQFciXHjgRQHFgRGMBmQCXAqBZApIAAADIAFAAQBXAoCUAoQGKBmHDgQQHhgSFciUIAGAAIAAgDQFciXHkgRQHEgRGMBmQCXAqBZApIAAADIAFAAQBXAoCUAoQGKBmHDgQQHhgSFciUIABAAIAAU7g");
        this.shape.setTransform(1920.5, 76.1786);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

      }).prototype = p = new cjs.MovieClip();
      p.nominalBounds = new cjs.Rectangle(0.5, 0.5, 3840, 151.4);


      (lib.Wave_Largeai = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Wave_Large_ai
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#00548A").s().p("Ekr/ANIIAA07IAHAAQCjiRDWhWQDNhSDmgUQDigUDeArQDiArDABmQBHAmA0AjQBAArAzAxIADAAQA0AwA/ArQA1AjBHAnQDCBmDgArQDfArDigUQHlgqFHkjIADAAQFHkjHlgqQDigUDeArQDiArDABmQBHAmA0AjQA/ArA0AxIADAAQA0AwBAArQA0AjBHAnQDCBmDgArQDfArDjgUQHkgqFHkjIADAAQFHkjHlgqQDigUDeArQDhArDBBmQBHAmA0AjQA/ArA0AxIACAAQA1AwA/ArQA0AjBIAnQDBBmDhArQDeArDjgUQHlgqFHkjIADAAQFHkjHkgqQDjgUDeArQDhArDABmQBHAmA1AjQA/ArA0AxIACAAQA1AwA/ArQA0AjBIAnQDBBmDhArQDeArDjgUQHlgqFHkjIADAAQCiiRDWhWQDNhSDmgUQDigUDfArQDhArDABmQBIAmAzAjQBAArA0AxIACAAQA0AwBAArQA0AjBIAnQDBBmDhArQDfArDigUQHkgqFHkjIACAAQCkiRDWhWQDMhSDngUQDhgUDfArQDhArDABmQBIAmAzAjQBAArA0AxIACAAQA1AwA/ArQA0AjBIAnQDBBmDhArQDfArDigUQHkgqFIkjIACAAQCkiRDWhWQDMhSDngUQDigUDdArQDiArDBBmQBHAmAzAjQBAArA0AxIADAAQA0AwA/ArQA0AjBIAnQDBBmDhArQDeArDjgUQHkgqFIkjIACAAQCjiRDXhWQDMhSDngUQDigUDdArQDiArDABmQBHAmA0AjQBAArA0AxIADAAQAzAwBAArQA0AjBIAnQDBBmDhArQDeArDjgUQHlgqFHkjIACAAQCjiRDWhWQDOhSDlgUQDigUDfArQDiArDABmQBHAmA0AjQA/ArA0AxIADAAQAzAwBAArQA0AjBIAnQDBBmDhArQDeArDjgUQHlgqFHkjIADAAQCiiRDWhWQDOhSDlgUQDigUDfArQDhArDBBmQBHAmA0AjQA/ArA0AxIACAAQA1AwA/ArQA0AjBIAnQDABmDgArQDfArDhgUQHjgpFIkhIAAU4g");
        this.shape.setTransform(1920.5, 84.4433);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

      }).prototype = p = new cjs.MovieClip();
      p.nominalBounds = new cjs.Rectangle(0.5, 0.5, 3840, 167.9);


      (lib.Path = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Laag_1
        this.shape = new cjs.Shape();
        this.shape.graphics.f("#FFFFFF").s().p("AAIBPQgUgDgXgZQgvgzgngiIgdgaQgJgJACgFQADgJAqAEIB4AMQAyADA5ABQAiADAHAOQAJASgVAfQgKARgPAOQgQARgUAMQgfASgdAAIgPgCg");
        this.shape.setTransform(15.8691, 8.1016);

        this.timeline.addTween(cjs.Tween.get(this.shape).wait(1));

        this._renderFirstFrame();

      }).prototype = getMCSymbolPrototype(lib.Path, new cjs.Rectangle(0, 0.1, 31.7, 16.099999999999998), null);


      (lib.Symbool1 = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // Laag_1
        this.instance = new lib.Path();
        this.instance.setTransform(263, 37.8, 1, 1, 0, 0, 0, 15.8, 8.1);
        this.instance.alpha = 0.6484;

        this.shape = new cjs.Shape();
        this.shape.graphics.f("#CD7D00").s().p("ADSEEIg3gKIndjBIiuhfIgcgUIg1guIgrgzIgihCIAAgtQCsCEEEBLQEMBNEyAAQAbACAkAAQBoAABLgSIAvBHIAOAjIgEAkIgKATIgTAXIgkAbIgqAUIggAKIg9AOIg2AHIhjADg");
        this.shape.setTransform(89.025, 242.05);

        this.shape_1 = new cjs.Shape();
        this.shape_1.graphics.f("#F39200").s().p("AFIJBIg3gKInejAIithhIgdgTIg1guIgrg0IgihBIAAgtQhxhWg8hlQg+hoAAhvIACgoIAKgwIAQgsIAdgvIARgSIAmgbIAVgHIAagCIAjAJICWBAIBlAeIBdAPICIAJICcgGICjgUIARAAIAVAFIAeAKIATAKIAYATIATAYIApBCIBmDTIBLDCIAmBWIArBRIBsCoIAPAjIgFAjIgKAUIgSAWIglAbIgqAUIgfAKIg9AOIg3AIIhjACg");
        this.shape_1.setTransform(77.275, 210.3);

        this.shape_2 = new cjs.Shape();
        this.shape_2.graphics.f("#FFFFFF").s().p("Ag3A4QgYgXAAghQAAggAYgXQAWgYAhAAQAhAAAXAYQAYAXAAAgQAAAhgYAXQgXAYghAAQghAAgWgYg");
        this.shape_2.setTransform(186.35, 151.5);

        this.shape_3 = new cjs.Shape();
        this.shape_3.graphics.f("#380400").s().p("AknEoQh6h7AAitQAAisB6h7QB7h6CsAAQCtAAB7B6QB6B7AACsQAACth6B7Qh7B6itAAQisAAh7h6g");
        this.shape_3.setTransform(194.325, 166.675);

        this.shape_4 = new cjs.Shape();
        this.shape_4.graphics.f("#FFFFFF").s().p("Al1F2QibibAAjbQAAjaCbibQCbibDaAAQDbAACbCbQCbCbAADaQAADbibCbQibCbjbAAQjaAAibibg");
        this.shape_4.setTransform(205.375, 168.675);

        this.shape_5 = new cjs.Shape();
        this.shape_5.graphics.f("#AA1922").s().p("Aj6YBIhZgRIikgvIjdhdIiqhkIh2heIiZidIiMi6IhPiGIhJiiIgWg7Igih5IgjjMIADjIIAKhWIAaiJIA6izIAzhzIA9htIBciJICEibICSiIICbh0ICXhaIBngyICTg5IC0gvIC5gYIBcAAIC7AOIClAjIAbgTIA8ghIA0gXIBWgcICCgaIBhAAIBFAMIAxAQIAiAQIAjAXIAaAXIAUAXIAXAkIAVAtIAXBZIAGAsIgDBQIgSBUIgOAhIgKASIAAAUIAkAmICQC0IAbAoIBpC+IA3CGIAqCUIAVB1IALBqIgGDrIgTCCIgkCQIg5CfIhNCaIhvCnIiNChIhbBSIiiB4IgxAeIiLBIIg1AYIihA5IiAAgIi4AaIjeADg");
        this.shape_5.setTransform(236, 155.625);

        this.shape_6 = new cjs.Shape();
        this.shape_6.graphics.f().s("#AB1923").ss(18, 1).p("Ap/CuQCsA6DMgKQC8gJC8hBQC0g+CMhhQCNhkBChw");
        this.shape_6.setTransform(422.45, 504.2735);

        this.shape_7 = new cjs.Shape();
        this.shape_7.graphics.f().s("#AB1923").ss(18, 1).p("AK9llQgeCpiPCXQiHCNjSBkQjMBijnAlQjuAmjSgl");
        this.shape_7.setTransform(441.775, 424.5377);

        this.shape_8 = new cjs.Shape();
        this.shape_8.graphics.f("#8C161A").s().p("Ar2VAIiSgdIhPgYIhbgiIhxg3IgvgbIiAhXIh6hsIAAgCQjCi6hrj3QhvkAAAkaQAAjpBNjaQBKjTCLivIgBgIIBXhoICFh9ICXhqIBqg5ICDg2IB4gkIDJgjICqADIBlALIA/ALICUAlICIA3IBnA1ICEBZIDjDEIAxAhIBiA3IBDAdICDApICiAdIBJAFICxgEIAigEICrgiIB3goIBdgsIBag5IA+gyIA4g6IA4hQIAagQIAOAFIARANIgEEZIgWDTIgeCjIhIEDIhsEPIh4DeIiVDYIilC+IidCSIh7BgIjCB9IjLBkIjPBLIjFAvIi4AYIo7AGg");
        this.shape_8.setTransform(313.275, 465.05);

        this.shape_9 = new cjs.Shape();
        this.shape_9.graphics.f("#AB1923").s().p("EgDoAhgIjYgVIipgeIkMhKIj3hgIkgicIiGhcIhOg7IjDiwIiTijIhYhzIiBjQIhai7Igwh7Ig7jSIggi0IgQjGIABh5IAVjnIAHgsIA7j2IA+ipIBkjSICMjbIBliBIC+jGICYiAID0imICchVIBEggICrhGIDJhCIC9gsIDBgeIDUgQICpAAIChALICtAZIBbATICRAoIB7AmICNA8IEpCfICaBHIBHAZIBaAQIA3ABIA3gFIBIgOIBDgUIBbgpIA0gjIA2g1IAzhOIAagaIATgHIAZAAIAUAGIApAXIAsAkIAZAZIA2BFIBHB4IA+CKIAcBOIAjCZIAhENIALD6IgBCXIgYFKIgVCcIhYGmIhLD3Ig6CbIiJEoIiBDPIiEClIh+CGIjXC5Ij6ClIiJBJIkNByIkuBVIkUAtIjTAOg");
        this.shape_9.setTransform(285.8, 430.25);

        this.timeline.addTween(cjs.Tween.get({}).to({ state: [{ t: this.shape_9 }, { t: this.shape_8 }, { t: this.shape_7 }, { t: this.shape_6 }, { t: this.shape_5 }, { t: this.shape_4 }, { t: this.shape_3 }, { t: this.shape_2 }, { t: this.shape_1 }, { t: this.shape }, { t: this.instance }] }).wait(1));

        this._renderFirstFrame();

      }).prototype = getMCSymbolPrototype(lib.Symbool1, new cjs.Rectangle(0, 0, 529.5, 645.2), null);


      (lib.logo_vector_rgbai = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // logo_vector_rgb_ai
        this.instance = new lib.Symbool1();
        this.instance.setTransform(264.8, 322.6, 1, 1, 0, 0, 0, 264.8, 322.6);

        this.timeline.addTween(cjs.Tween.get(this.instance).wait(1).to({ y: 316.45 }, 0).wait(1).to({ y: 310.25 }, 0).wait(1).to({ y: 304.05 }, 0).wait(1).to({ y: 297.85 }, 0).wait(1).to({ y: 291.65 }, 0).wait(1).to({ y: 285.45 }, 0).wait(1).to({ y: 279.25 }, 0).wait(1).to({ y: 273.05 }, 0).wait(1).to({ y: 266.85 }, 0).wait(1).to({ y: 260.65 }, 0).wait(1).to({ y: 254.45 }, 0).wait(1).to({ y: 248.25 }, 0).wait(1).to({ y: 242.05 }, 0).wait(1).to({ y: 241.9 }, 0).wait(1).to({ y: 241.85 }, 0).wait(2).to({ y: 242 }, 0).wait(1).to({ y: 242.35 }, 0).wait(1).to({ y: 243 }, 0).wait(1).to({ y: 245.05 }, 0).wait(1).to({ y: 252.2 }, 0).wait(1).to({ y: 261.65 }, 0).wait(1).to({ y: 273.35 }, 0).wait(1).to({ y: 287.2 }, 0).wait(1).to({ y: 303.3 }, 0).wait(1).to({ y: 322.05 }, 0).wait(1));

        this._renderFirstFrame();

      }).prototype = p = new cjs.MovieClip();
      p.nominalBounds = new cjs.Rectangle(0, -80.7, 529.5, 725.9000000000001);


      // stage content:
      (lib.header_race = function (mode, startPosition, loop, reversed) {
        if (loop == null) { loop = true; }
        if (reversed == null) { reversed = false; }
        var props = new Object();
        props.mode = mode;
        props.startPosition = startPosition;
        props.labels = {};
        props.loop = loop;
        props.reversed = reversed;
        cjs.MovieClip.apply(this, [props]);

        // wave_front
        this.instance = new lib.Wave_Smallai("synched", 0);
        this.instance.setTransform(96.55, 334.15, 1, 1, 0, 0, 0, 1920.5, 76);

        this.timeline.addTween(cjs.Tween.get(this.instance).to({ x: 480.55 }, 191).wait(1));

        // blub
        this.instance_1 = new lib.logo_vector_rgbai("synched", 12);
        this.instance_1.setTransform(2106.4, 177.7, 0.3811, 0.381, -9.9946, 0, 0, 253.8, 305.1);

        this.timeline.addTween(cjs.Tween.get(this.instance_1).to({ x: -216.95, startPosition: 0 }, 191).wait(1));

        // wave_back
        this.instance_2 = new lib.Wave_Largeai("synched", 0);
        this.instance_2.setTransform(1632.55, 309.5, 1, 1, 0, 0, 0, 1920.5, 84.5);

        this.timeline.addTween(cjs.Tween.get(this.instance_2).to({ x: 1248.55 }, 191).wait(1));

        this._renderFirstFrame();

      }).prototype = p = new lib.AnMovieClip();
      p.nominalBounds = new cjs.Rectangle(-863.4, 203.5, 4416, 206.5);
      // library properties:
      lib.properties = {
        id: '90F03647C15B4ADDB70E6D9ACD334532',
        width: 1920,
        height: 350,
        fps: 24,
        color: "#FFFFFF",
        opacity: 1.00,
        manifest: [],
        preloads: []
      };



      // bootstrap callback support:

      (lib.Stage = function (canvas) {
        createjs.Stage.call(this, canvas);
      }).prototype = p = new createjs.Stage();

      p.setAutoPlay = function (autoPlay) {
        this.tickEnabled = autoPlay;
      }
      p.play = function () { this.tickEnabled = true; this.getChildAt(0).gotoAndPlay(this.getTimelinePosition()) }
      p.stop = function (ms) { if (ms) this.seek(ms); this.tickEnabled = false; }
      p.seek = function (ms) { this.tickEnabled = true; this.getChildAt(0).gotoAndStop(lib.properties.fps * ms / 1000); }
      p.getDuration = function () { return this.getChildAt(0).totalFrames / lib.properties.fps * 1000; }

      p.getTimelinePosition = function () { return this.getChildAt(0).currentFrame / lib.properties.fps * 1000; }

      an.bootcompsLoaded = an.bootcompsLoaded || [];
      if (!an.bootstrapListeners) {
        an.bootstrapListeners = [];
      }

      an.bootstrapCallback = function (fnCallback) {
        an.bootstrapListeners.push(fnCallback);
        if (an.bootcompsLoaded.length > 0) {
          for (var i = 0; i < an.bootcompsLoaded.length; ++i) {
            fnCallback(an.bootcompsLoaded[i]);
          }
        }
      };

      an.compositions = an.compositions || {};
      an.compositions['90F03647C15B4ADDB70E6D9ACD334532'] = {
        getStage: function () { return exportRoot.stage; },
        getLibrary: function () { return lib; },
        getSpriteSheet: function () { return ss; },
        getImages: function () { return img; }
      };

      an.compositionLoaded = function (id) {
        an.bootcompsLoaded.push(id);
        for (var j = 0; j < an.bootstrapListeners.length; j++) {
          an.bootstrapListeners[j](id);
        }
      }

      an.getComposition = function (id) {
        return an.compositions[id];
      }


      an.makeResponsive = function (isResp, respDim, isScale, scaleType, domContainers) {
        var lastW, lastH, lastS = 1;
        window.addEventListener('resize', resizeCanvas);
        resizeCanvas();
        function resizeCanvas() {
          var w = lib.properties.width, h = lib.properties.height;
          var iw = domContainers[1].clientWidth, ih = window.innerHeight;
          var pRatio = window.devicePixelRatio || 1, xRatio = iw / w, yRatio = ih / h, sRatio = 1;
          if (isResp) {
            if ((respDim == 'width' && lastW == iw) || (respDim == 'height' && lastH == ih)) {
              sRatio = lastS;
            }
            else if (!isScale) {
              if (iw < w || ih < h)
                sRatio = Math.min(xRatio, yRatio);
            }
            else if (scaleType == 1) {
              sRatio = Math.min(xRatio, yRatio);
            }
            else if (scaleType == 2) {
              sRatio = Math.max(xRatio, yRatio);
            }
          }
          domContainers[0].width = w * pRatio * sRatio;
          domContainers[0].height = h * pRatio * sRatio;
          domContainers.forEach(function (container) {
            if (container != domContainers[1]) {
              container.style.width = w * sRatio + 'px';
            }
            container.style.height = h * sRatio + 'px';
          });
          stage.scaleX = pRatio * sRatio;
          stage.scaleY = pRatio * sRatio;
          lastW = iw; lastH = ih; lastS = sRatio;
          stage.tickOnUpdate = false;
          stage.update();
          stage.tickOnUpdate = true;
        }
      }
      an.handleSoundStreamOnTick = function (event) {
        if (!event.paused) {
          var stageChild = stage.getChildAt(0);
          if (!stageChild.paused || stageChild.ignorePause) {
            stageChild.syncStreamSounds();
          }
        }
      }
      an.handleFilterCache = function (event) {
        if (!event.paused) {
          var target = event.target;
          if (target) {
            if (target.filterCacheList) {
              for (var index = 0; index < target.filterCacheList.length; index++) {
                var cacheInst = target.filterCacheList[index];
                if ((cacheInst.startFrame <= target.currentFrame) && (target.currentFrame <= cacheInst.endFrame)) {
                  cacheInst.instance.cache(cacheInst.x, cacheInst.y, cacheInst.w, cacheInst.h);
                }
              }
            }
          }
        }
      }

    })(createjs, AdobeAn);

    var canvas, stage, exportRoot, anim_container, dom_overlay_container, fnStartAnimation;

    function handleComplete(evt, comp) {
      //This function is always called, irrespective of the content. You can use the variable "stage" after it is created in token create_stage.
      var lib = comp.getLibrary();
      exportRoot = new lib.header_race();
      stage = new lib.Stage(canvas);
      //Registers the "tick" event listener.
      fnStartAnimation = function () {
        stage.addChild(exportRoot);
        createjs.Ticker.framerate = lib.properties.fps;
        createjs.Ticker.addEventListener("tick", stage);
      }
      //Code to support hidpi screens and responsive scaling.
      AdobeAn.makeResponsive(true, 'width', true, 1, [canvas, anim_container, dom_overlay_container]);
      AdobeAn.compositionLoaded(lib.properties.id);
      fnStartAnimation();
    }

    anim_container = this.element;
    canvas = this.element.getElementsByTagName("canvas")[0];
    dom_overlay_container = this.element.getElementsByTagName('div')[0];
    var comp = AdobeAn.getComposition("90F03647C15B4ADDB70E6D9ACD334532");
    handleComplete({}, comp);
  }
}
