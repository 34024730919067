import { Controller } from "@hotwired/stimulus"
import { Tooltip } from 'bootstrap'

export default class extends Controller {
  static targets = ["trigger"]

  connect() {
    return new Tooltip(this.triggerTarget)
  }
}
